import React from "react";
import { Location } from "@reach/router";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MarTech from "../../components/MarTech";
import ImgWithOverlay from "../../components/img-with-overlay";
import PostGrid from "../../components/PostGrid";

function CustomSoftwareDevelopment(props) {
  const {
    building,
    needSoftware,
    awsConsole,
    datacenter,
    serverless,
    switchboard,
    cash,
    binoculars,
  } = props.data

  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title="Custom Software Development"
            pathname={location.pathname}
            keywords={[
              "Custom Software Development",
              "React",
              "React.js",
              "app development",
              "software development process",
              "gortek",
              "AWS Cloud Services",
              "API Development",
              "AWS Lambda microservices",
              "Data Processing",
              "Data Migration",
              "ETL",
              "Python",
              "Apache Airflow"
            ]}
          />
        )}
      </Location>

      <MarTech />

      <section>
        <ImgWithOverlay
          filename="meeting.jpg"
          backgroundColor="#1464f6bb"
          backgroundPosition="bottom"
        >
          <div className="flex h-full flex-col">
            <h1 className="mt-auto text-white w-full text-center">
              Custom Software Development
            </h1>
            <h2 className="text-white text-2xl w-full text-center pb-4">
              Gortek is the software development company for agile business
              leaders
            </h2>
          </div>
        </ImgWithOverlay>
      </section>

      <section className="mt-20">
        <div className="flex sm:flex-row-reverse flex-col">
          <div className="sm:w-1/2 w-full  sm:p-3 p-0">
            <Img
              alt="Grow your business"
              title="Grow your business"
              fluid={building.childImageSharp.fluid}
              draggable={false}
            />
          </div>
          <div className="sm:w-1/2 w-full sm:pr-6 pr-0">
            <h2 className="text-primary text-3xl">
              Does your business need custom software?
            </h2>
            <p className="mt-3">
              <strong>Gortek is a small firm with big capabilities.</strong>
            </p>
            <p className="mt-3">
              <em>
                Being led by a hands-on software engineer with many years of
                experience means we can easily translate your business needs
                into robust software systems.
              </em>
            </p>

            <p className="mt-3">We punch well above our weight.</p>
            {/* <p className="mt-3"> */}
            {/*   There are numerous scenarios where an existing business needs to */}
            {/*   develop custom software. This page explores a sampling of them. */}
            {/* </p> */}
            <p className="mt-3">
              Our team can handle a range of projects from small one-off tasks,
              new product and app development, to full infrastructure
              modernization initiatives.
            </p>
            <p className="mt-3">
              We run very lean, have low overhead, and can scale our development
              staff to meet the needs of your project requirements.
            </p>
          </div>
        </div>
      </section>

      <section className="flex md:flex-row flex-wrap mt-8">
        <div className="sm:w-1/2 w-full  sm:p-3 p-0">
          <Img
            alt="Achieve your goals"
            title="Achieve your goals"
            fluid={needSoftware.childImageSharp.fluid}
            draggable={false}
          />
        </div>
        <div className="sm:w-1/2 w-full pr-6">
          <h2 className="text-primary text-3xl mt-8 sm:mt-2">New Product Development</h2>
          <p className="mt-2">
            Developing a new app or product for an existing business has a few
            key differences from a startup.
          </p>
          <p className="mt-2">
            From a <em>product</em> perspective, the existing business usually
            has a much better understanding of their goals and customer base.
          </p>
          <p className="mt-2">
            From a <em>project</em> perspective, the larger business has more
            resources and staff to coordinate with. A more rigorous process is
            usually desired, and decisions can take longer. Increase your
            agility by offloading some of that effort to our small team.
          </p>
          <p className="mt-2">
            Our team can adapt to the shape, size, and skillset of yours, while
            bringing our own expertise to bear on developing your digital
            product. This ensures everyone's concerns are addressed and we match
            the appropriate level of technical detail to each person we converse
            with.
          </p>
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <h2 className="text-primary text-3xl mt-8">AWS Management Services</h2>
          <p className="mt-2">
            Amazon Web Services is our preferred cloud provider and is the
            default choice when building any new product or app. As a result,
            we're advanced users ourselves and automatically apply AWS best
            practices as part of our internal processes.
          </p>
          <p className="mt-2">
            We encourage our clients to perform an audit of their AWS accounts
            and offer assistance where needed. This will highlight potential
            problems in your AWS configuration including security issues, and
            possibly opportunities to reduce cost.
          </p>
          <p className="mt-2">
            It's not uncommon that our engagements involve some amount of
            education to teach you how to use the AWS Management Console.
          </p>
        </div>

        <div className="sm:w-1/2 w-full  sm:p-3 p-0 mt-6">
          <Img
            alt="AWS Console"
            title="AWS Console"
            fluid={awsConsole.childImageSharp.fluid}
            draggable={false}
          />
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <h2 className="text-primary text-3xl mt-8">API Development</h2>
          <p className="mt-2">
            Most every time we develop an app there is an API created along side
            the front-end software as a matter of practice.
          </p>
          <p className="mt-2">
            Sometimes though, a business just needs to build an API. It could be
            to expose an API around an existing piece of software that doesn't
            natively have one. Or, to extend the capability of an app so
            partners and vendors can securely integrate into your business. Or
            as the back-end to an IoT device.
          </p>
          <p className="mt-2">
            Whatever the case, we'll architect a solution tailored to your
            needs.
          </p>
          <p className="mt-2">
            One pattern we've found to be highly effective is building custom
            microservices using AWS Lambda. This core functionality can be
            wrapped by specific endpoint technologies &mdash; API Gateway for
            REST, AppSync for GraphQL, and gRPC for service to service
            communication.
          </p>
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <h2 className="text-primary text-3xl mt-8">Data Migration and ETL</h2>
          <div className=" md:flex md:flex-row md:flex-row-reverse">
            <div className="flex-1 ">
              <p className="mt-2">
                Need to move data from one system to another?
              </p>
              <p className="mt-2">
                Change the format, clean, and augment data as part of a pipeline
                that serves your needs.
              </p>
              <p className="mt-2">
                Python is usually the tool of choice here. Let's talk to see
                what solution best fits your needs. A simple script based
                pipeline, or a formal data processing workflow using{" "}
                <a
                  className="text-primary underline"
                  href="https://airflow.apache.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apache Airflow
                </a>
                .
              </p>
            </div>
            <div className="mt-6 md:mt-4 flex-initial flex flex-row md:flex-col md:mr-4">
              <img
                alt="Python Programming Language Logo"
                title="Python Programming Language Logo"
                className="h-20 w-20 md:mb-4"
                src={require(`../../images/python-logo.svg`)}
              />
              <img
                alt="Apache Airflow Logo"
                title="Apache Airflow Logo"
                className="h-20 w-20"
                src={require(`../../images/airflow-pinwheel.png`)}
              />
            </div>
          </div>
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <div className="mt-10 ">
            <Img
              alt="On-premises hardware"
              title="On-premises hardware"
              fluid={datacenter.childImageSharp.fluid}
              draggable={false}
            />
          </div>
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <h2 className="text-primary text-3xl mt-8">Cloud Migration</h2>

          <p className="mt-2">
            If you're running on-premises hardware the not-so-new paradigm of
            low-cost, pay-as-you-go, fully managed serverless cloud services is
            undeniably a game changer that you need to seriously consider.
          </p>
          <p className="mt-2">
            Your question of &ldquo;Why is it better to use cloud
            services?&rdquo; is answered every time your financial guy (or gal)
            has <em>that talk</em> with you.
          </p>
          <p className="mt-2">
            It's not just reduced capital expenditure from less space, power,
            and HVAC requirements. But also better security, operational agility
            in terms of scale up and down with demand, and increased reliability
            when deployed to multiple availability zones.
          </p>
          <p className="mt-2">
            Gortek will help you level up your business by adopting AWS cloud
            services.
          </p>
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <h2 className="text-primary text-3xl mt-8">
            Migration to Serverless Architecture
          </h2>
          <p className="mt-2">
            This is the holy grail of software infrastructure. Completely
            hands-off managed services that run your software at the highest
            levels of reliability and instantaneous scalability.
          </p>
          <p className="mt-2">
            This goes well beyond just trading your big iron for EC2 instances.
            Your modernization effort can include reimplementing software with a
            serverless architecture.
          </p>
          <p className="mt-2">
            Were happy to discuss your needs together and formulate a
            comprehensive plan.
          </p>
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <div className="mt-10 ">
            <Img
              alt="Serverless Architecture"
              title="Serverless Architecture"
              fluid={serverless.childImageSharp.fluid}
              draggable={false}
            />
          </div>
        </div>

        <div className="sm:w-1/2 w-full pr-6">
          <div className="mt-10 ">
            <Img
              alt="Telecom Switchboard - courtesy of Joseph A. Carr"
              title="Telecom Switchboard - courtesy of Joseph A. Carr"
              fluid={switchboard.childImageSharp.fluid}
              draggable={false}
            />
            <div className="text-center text-xs">
              <strong>Are you connecting tools like it's the 1950s?</strong>{" "}
              Image Credit: Joseph A. Carr
            </div>
          </div>
        </div>
        <div className="sm:w-1/2 w-full pr-6">
          <h2 className="text-primary text-3xl mt-8">Work Automation</h2>
          <p className="mt-2">
            Monotonous tasks suck the life out of people. They are error prone.
            They cause rework, delays, and cost money.
          </p>
          <p className="mt-2">
            {" "}
            Many have tried using{" "}
            <a
              className="text-primary underline"
              href="https://ifttt.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              IFTTT
            </a>{" "}
            and{" "}
            <a
              className="text-primary underline"
              href="https://zapier.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zapier
            </a>{" "}
            to glue together a hodge-podge solution. Those are neat tools for
            simplistic tasks, but aren't sufficient for sophisticated business
            needs.
          </p>
          <p className="mt-2">
            Elevate your business to the professional level it should be at with
            purpose built, custom software.
          </p>
          <p className="mt-2">
            Intentionally designed software will blast through the problems and
            painpoints caused by inadequate tooling. We'll model and fully
            capture your business process, then automate it using the right tool
            for the job.
          </p>
          <p className="mt-2">
            Work with Gortek to create the complete solution, use the best
            technology, and rest assured of its reliability for your business.
          </p>
        </div>
      </section>

      <section className="mt-32 mx-auto ssm:w-2/3">
        <h2 className="text-primary text-4xl text-center">Everyone's Top Question</h2>
        <h3 className="text-primary text-2xl text-center">
          How much is this going to cost?
        </h3>
        <div className=" flex md:flex-row flex-wrap mt-8">
          <div className="sm:w-1/2 w-full pr-6">
            <div className="mt-10 sm:mt-2 ">
              <Img
                alt="A case full of money"
                title="A case full of money"
                fluid={cash.childImageSharp.fluid}
                draggable={false}
              />
              <div className="text-center text-xs">
                <strong>
                  This is how much money you can <em>save</em> with a discovery
                  project.
                </strong>
              </div>
            </div>
            <div className="mt-10 sm:mt-2 ">
              <Img
                alt="Woman using binoculars"
                title="We use discovery projects to see into the future"
                fluid={binoculars.childImageSharp.fluid}
                draggable={false}
              />
              <div className="text-center text-xs">
                <strong>
                  We use discovery projects to see into the future.
                </strong>
              </div>
            </div>
          </div>
          <div className="sm:w-1/2 w-full pr-6">
            <p className="mt-2">
              <strong>75% of software projects fail.</strong>
            </p>

            <p className="mt-2">
              Software project estimations are infamous for being wildly
              inaccurate.
            </p>
            <p className="mt-2">
              Tech news is full of cost overruns, software that doesn't work,
              and products that never see the light of day.
            </p>

            <p className="mt-2">
              <strong>Gortek has discovered a solution to this problem</strong>.
            </p>
            <p className="mt-2">It's not rocket science. It's common sense.</p>

            <p className="mt-2">
              The secret is to make the unknowns known. Rather than try to
              predict far into the future, we simply go there.
            </p>
            <p className="mt-2">
              As a matter of practice, we start our journey together with a
              small exploration project to document your business goals for the
              project, must-have and nice-to-have features & functionality, and
              user flows.
            </p>
            <p className="mt-2">
              We develop mockups. We perform data analysis, sketch out schemas,
              interfaces, architecture, and dependencies.
            </p>

            <p className="mt-2">
              <strong>You wouldn't build a house without a blueprint.</strong>
            </p>

            <p className="mt-2">
              We learn as much as we can <em>before</em> we estimate overall
              time frames, level of effort required, and costs.
            </p>
            <p className="mt-2">
              This is real work. It makes all the difference, and it's not free.
            </p>
            <p className="mt-2">
              By doing this, we uncover complications up front.
            </p>
            <p className="mt-2">
              We discover the curveball that would otherwise derail the project
              after its underway and committed to a course of action.
            </p>

            <p className="mt-2">
              <strong>
                Are you ready for more realistic project estimates?
              </strong>
            </p>

            <p className="mt-2">
              Lets{" "}
              <Link
                to="/services/discovery-project"
                className="text-primary underline"
              >
                <strong>book a discovery project</strong>
              </Link>{" "}
              to learn the true cost of your solution.
            </p>
          </div>
        </div>
      </section>

      {/*
      <section>
        <PostGrid layout="list" showImage={true} />
      </section>
      */}

      <div className="mb-16" />
    </Layout>
  );
}

export default CustomSoftwareDevelopment;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export const query = graphql`
  query {
    building: file(relativePath: { eq: "building.jpg" }) {
      ...fluidImage
    }
    needSoftware: file(relativePath: { eq: "need-custom-software.jpg" }) {
      ...fluidImage
    }
    awsConsole: file(relativePath: { eq: "aws-console.png" }) {
      ...fluidImage
    }
    datacenter: file(relativePath: { eq: "datacenter.jpg" }) {
      ...fluidImage
    }
    serverless: file(relativePath: { eq: "serverless-architecture.png" }) {
      ...fluidImage
    }
    switchboard: file(relativePath: { eq: "switchboard-and-operator.jpg" }) {
      ...fluidImage
    }
    cash: file(relativePath: { eq: "cash.jpg" }) {
      ...fluidImage
    }
    binoculars: file(relativePath: { eq: "binoculars.jpg" }) {
      ...fluidImage
    }
  }
`
